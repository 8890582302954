import React from 'react';
import styled from 'styled-components';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';

import IconArrow from 'images/icons/swiper-arrow.svg';
import IconArrowDisabled from 'images/icons/swiper-arrow-disabled.svg';

const StyledSwiper = styled(ReactSwiper)`
  .swiper-button-prev,
  .swiper-button-next {
    width: 28px;
    height: 28px;
    transform: translateY(25%);
    transform-origin: center;
    top: 50%;
    background-image: url(${IconArrow});
    background-repeat: no-repeat;

    &::after {
      content: '';
    }

    &.swiper-button-disabled {
      opacity: unset;
      pointer-events: auto;
      background-image: url(${IconArrowDisabled});
    }
  }

  .swiper-button-next {
    transform: translateY(25%) rotate(0deg);

    &.swiper-button-disabled {
      transform: translateY(25%) rotate(180deg);
    }
  }

  .swiper-button-prev {
    transform: translateY(25%) rotate(180deg);

    &.swiper-button-disabled {
      transform: translateY(25%) rotate(0deg);
    }
  }

  .swiper-slide {
    cursor: pointer;
    height: auto;
  }
`;

const Swiper = (props) => {
  return <StyledSwiper {...props} />;
};

export { Swiper, SwiperSlide };
