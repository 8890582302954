// 統一編號
const checkTaxNumberV2 = (gui_number = '') => {
  let rule = '12121241';
  if (gui_number.length !== 8) {
    return false;
  }
  let isSeven = false;
  // console.log('checkTaxNumberV2 !== 7', gui_number);
  if (gui_number[gui_number.length - 2] === '7') {
    // console.log('checkTaxNumberV2 === 7', gui_number);
    isSeven = true;
  }

  let z1 = [];
  let z2 = [];
  for (let i = 0; i < gui_number.length; i++) {
    let result = parseInt(gui_number[i]) * parseInt(rule[i]);
    if (result >= 10) {
      let a = Math.floor(result / 10);
      let b = result % 10;
      if (i === 6 && isSeven) {
        let c = a + b;
        let d = Math.floor(c / 10);
        let e = c % 10;

        z1.push(d);
        z2.push(e);
      } else {
        z1.push(a);
        z1.push(b);
        z2.push(a);
        z2.push(b);
      }
    } else {
      z1.push(result);
      z2.push(result);
    }
  }
  // console.log('checkTaxNumberV2 z1', z1);
  // console.log('checkTaxNumberV2 z2', z2);
  let r1 = 0;
  z1.forEach((element) => {
    r1 += element;
  });
  let r2 = 0;
  z2.forEach((element) => {
    r2 += element;
  });
  // console.log('checkTaxNumberV2 r1', r1);
  // console.log('checkTaxNumberV2 r2', r2);
  if (isSeven) {
    if (r1 % 5 === 0 || r2 % 5 === 0) {
      return true;
    }
  } else {
    if (r1 % 5 === 0) {
      return true;
    }
  }

  return false;
};
export { checkTaxNumberV2 };
