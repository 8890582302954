import React, { useEffect, useState } from 'react';
import { useAPI } from 'utils/api';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import styled from 'styled-components';
import SwiperProduct from 'components/Swiper/SwiperProduct';

const SwiperProductWrapper = styled.div`
  margin-top: 16px;
`;

const AdditionBlock = ({ remove, update }) => {
  const additionItems = useSelector((state) => getters.getAdditionItems(state));
  const api = useAPI();
  const [additionList, setAdditionList] = useState([]);

  const fetchAdditionItemList = () => {
    setAdditionList([]);
    const params = {
      shippingModel: 1,
    };
    api
      .getAddtionItems(params)
      .then((res) => {
        store.dispatch(actions.setAdditionItems(res.rows));
        if (res.rows.length > 0) {
          const arrItems = res.rows.slice(0, res.show);
          setAdditionList(arrItems);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchAdditionItemList();
  }, []);

  return (
    <div>
      {additionList.length > 0 && (
        <SwiperProductWrapper>
          <SwiperProduct
            title="加價購商品"
            list={additionList}
            update={update}
            remove={remove}
            checkMore={() => {
              navigate('/addition');
            }}
            onClickCard={() => {}}
          />
        </SwiperProductWrapper>
      )}
    </div>
  );
};
export default AdditionBlock;
