import React, { useEffect, useState, useRef } from 'react';
import { getters } from 'redux/store';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import imageCross from 'images/icons/i-cross-gray.svg';
import imagePlus from 'images/icons/i-plus-green.svg';
import imageMinus from 'images/icons/i-minus-green.svg';

import { useAPI } from 'utils/api';

const Container = styled.div`
  padding: 16px 2px 0;
  border-top: 8px solid #e4e4e4;
  border-bottom: 8px solid #e4e4e4;
`;

const ItemWrapper = styled.div``;

const ItemCheckBox = styled.div`
  margin-bottom: 10px;
`;
const CheckBoxLabel = styled.label``;

const ItemBlock = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ProductImage = styled.div`
  width: 70px;
  height: 70px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    left: -5px;
  }
`;

const ItemTitleWrapper = styled.div`
  width: calc(100% - 119px);
`;

const ItemMainTitle = styled.div`
  height: 46px;
  font-size: 16px;
  line-height: 23px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ItemSubTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #aaaaaa;
`;

const IconWrapper = styled.div`
  margin: 4px 0 0 35px;
  img {
    width: 14px;
    height: 14px;
  }
`;

const QtyItemBlock = styled.div`
  padding: 3px 2px 5px 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ItemCounter = styled.div`
  width: 97px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 0 8px;
  span {
    font-size: 18px;
  }
`;

const RecommendBlock = styled.div`
  width: auto;
  background-color: #eafeff;
  font-size: 14px;
  font-weight: 400;
  color: #5fd2da;
  padding: 12px 0;
  text-align: center;
  margin: 10px -2px 0;
`;

const RecycleBagItem = ({
  itemData,
  itemArrData,
  checkBag,
  recycleBag,
  setCheckBag,
  remove,
  update,
  checkQtyFinish,
  isNeedCheckQty,
  saleOutHandle,
  recycleBagMessage,
}) => {
  const api = useAPI();
  const [item, setItem] = useState({});
  const [qtyDisable, setQtyDisable] = useState(true);
  const currentStoreId = useSelector((state) => getters.getStoreId(state));

  const deleteItem = () => {
    const recycleBag = true;
    api
      .removeCart({
        productId: item.productId,
        currentStoreId: currentStoreId,
      })
      .then((res) => {
        remove(res, recycleBag);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateItem = (mode) => {
    var count = 0;
    count = mode === 'increase' ? item.qty + 1 : item.qty - 1;
    if (count > item.c_maxOrderQuantity) {
      alert('已達商品購買上限');
      return;
    }

    api
      .updateCart({
        productId: item.productId,
        quantity: count,
        currentStoreId: currentStoreId,
      })
      .then((res) => {
        if (!res) return;
        update(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const recycleBagUpdate = (mode) => {
    api
      .updateCart({
        productId: recycleBag.productId,
        quantity: 1,
        currentStoreId: currentStoreId,
      })
      .then((res) => {
        if (!res) return;
        update(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const increaseQty = () => {
    updateItem('increase');
  };
  const recycleBagIncrease = () => {
    recycleBagUpdate();
  };

  const decreaseQty = () => {
    const countQty = item.qty - 1;
    if (countQty === 0) {
      setCheckBag(false);
      deleteItem();
      return;
    }
    updateItem('decrease');
  };

  const getCartCheckQty = () => {
    const parameters = {
      productlist: [
        {
          productId: item.productId,
          qty: itemData.qty,
        },
      ],
    };
    api
      .getCartCheckQty(parameters)
      .then((res) => {
        res.productItems.forEach((element) => {
          if (element.productId === item.productId) {
            setQtyDisable(false);
            if (element.remain != true) {
              saleOutHandle(itemData.itemName);
              // deleteItem();
              // alert(element.removereason, () => {
              //   deleteItem();
              // });
            }
          }
        });
        checkQtyFinish();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    itemArrData.forEach((element) => {
      if (element.productId === recycleBag.productId) {
        setItem(element);
      }
    });

    if (isNeedCheckQty) {
      getCartCheckQty();
    } else {
      setQtyDisable(false);
    }
  }, []);

  return (
    <>
      <Container>
        {qtyDisable ? (
          <ItemWrapper className="qty-disable">
            <ItemCheckBox>
              <CheckBoxLabel className="checkbox">
                <input
                  type="checkbox"
                  checked={checkBag}
                  onChange={(e) => {
                    if (checkBag) {
                      setCheckBag(false);
                      deleteItem();
                    } else {
                      setCheckBag(true);
                      recycleBagIncrease();
                    }
                  }}
                ></input>
                <span className="slider round">建議加購</span>
              </CheckBoxLabel>
            </ItemCheckBox>
            <ItemBlock>
              <ProductImage>
                <img src={recycleBag.image} />
              </ProductImage>

              <ItemTitleWrapper>
                <ItemMainTitle>{recycleBag.itemName}</ItemMainTitle>
                <ItemSubTitle>{recycleBag.itemSpec}</ItemSubTitle>
              </ItemTitleWrapper>

              <IconWrapper>
                {checkBag && (
                  <img
                    src={imageCross}
                    onClick={() => {
                      setCheckBag(false);
                      deleteItem();
                    }}
                  />
                )}
              </IconWrapper>
            </ItemBlock>

            <QtyItemBlock>
              {itemData.length > 0 ? (
                <>
                  <ItemCounter>
                    <img src={imageMinus} onClick={decreaseQty} />
                    <span>{item.qty}</span>
                    <img src={imagePlus} onClick={increaseQty} />
                  </ItemCounter>
                  <span>{`$${item.price}`}</span>
                </>
              ) : (
                <>
                  <ItemCounter>
                    <img src={imageMinus} />
                    <span>{1}</span>
                    <img src={imagePlus} />
                  </ItemCounter>
                  <span>{`$${recycleBag.basePrice}`}</span>
                </>
              )}
            </QtyItemBlock>
          </ItemWrapper>
        ) : (
          <ItemWrapper>
            <ItemCheckBox>
              <CheckBoxLabel className="checkbox">
                <input
                  type="checkbox"
                  checked={checkBag}
                  onChange={(e) => {
                    if (checkBag) {
                      setCheckBag(false);
                      deleteItem();
                    } else {
                      setCheckBag(true);
                      recycleBagIncrease();
                    }
                  }}
                ></input>
                <span className="slider round">建議加購</span>
              </CheckBoxLabel>
            </ItemCheckBox>
            <ItemBlock>
              <ProductImage>
                <img src={recycleBag.image} />
              </ProductImage>

              <ItemTitleWrapper>
                <ItemMainTitle>{recycleBag.itemName}</ItemMainTitle>
                <ItemSubTitle>{recycleBag.itemSpec}</ItemSubTitle>
              </ItemTitleWrapper>

              <IconWrapper>
                {checkBag && (
                  <img
                    src={imageCross}
                    onClick={() => {
                      setCheckBag(false);
                      deleteItem();
                    }}
                  />
                )}
              </IconWrapper>
            </ItemBlock>

            <QtyItemBlock>
              {itemData.length > 0 ? (
                <>
                  <ItemCounter>
                    <img src={imageMinus} onClick={decreaseQty} />
                    <span>{item.qty}</span>
                    <img src={imagePlus} onClick={increaseQty} />
                  </ItemCounter>
                  <span>{`$${item.price}`}</span>
                </>
              ) : (
                <>
                  <ItemCounter>
                    <img src={imageMinus} />
                    <span>{1}</span>
                    <img src={imagePlus} />
                  </ItemCounter>
                  <span>{`$${recycleBag.basePrice}`}</span>
                </>
              )}
            </QtyItemBlock>
          </ItemWrapper>
        )}
        <RecommendBlock>{`${recycleBagMessage}`}</RecommendBlock>
      </Container>
    </>
  );
};

export default RecycleBagItem;
