import React, { useEffect, useState } from 'react';
import store, { actions, getters } from 'redux/store';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Navigation } from 'swiper';
import { useSelector } from 'react-redux';

import { Swiper, SwiperSlide } from './index';
import Amounter from 'components/amounter';
import AdditionItem from 'components/addition-item';

import { useAPI } from 'utils/api';
import { setAddToCart } from 'utils/_ga';

import ImgArrowRight from 'images/icons/arrow-right.svg';

const SwiperBlockTitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const SwiperBlockTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #3b3516;
`;

const SwiperBlockCheckMore = styled.div`
  margin-left: auto;

  a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #1430b3;
  }
`;

const ProductItem = styled.div`
  padding: 10px 5px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;

  .showDetail-block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .showtitle {
      /* Frame 1 */
      box-sizing: border-box;
      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 2px 6px;
      gap: 10px;
      justify-content: space-between;
      width: auto;
      height: 18px;
      left: 75px;
      top: 76px;

      background: #fdf8e4;
      border: 1px solid #f2ecd2;
      border-radius: 20px;

      .showcontent {
        width: 75px;
        height: 14px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        /* 除去多餘字元 */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

const ProductItemImg = styled.div`
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
  }
`;

const ProductItemPriceBlock = styled.div`
  height: 23px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3px;
`;

const ProductItemPrice = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #3b3516;

  span {
    font-size: 12px;
    line-height: 17px;
    color: #3b3516;
  }
`;

const ProductItemOriginalPrice = styled.div`
  font-size: 12px;
  line-height: 17px;
  text-decoration-line: line-through;
  color: #999999;
`;

const ProductItemTitle = styled.div`
  margin-top: 3px;
  font-size: 12px;
  line-height: 17px;
  height: 34px;
  color: #3b3516;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CounterWrapper = styled.div`
  position: absolute;
  bottom: 28px;
  left: 0;
  right: 0;
`;

const Card = styled.div`
  width: auto;
  height: 100%;
  padding: 10px 5px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 8px;
  color: #5fd2da;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SLIDES_PER_VIEW = 2.5;
const SPACE_BETWEEN = 8;

const SwiperProduct = (props) => {
  const {
    title = '',
    checkMore,
    swiper = {
      slidesPerView: SLIDES_PER_VIEW,
      slidesPerGroup: Math.floor(SLIDES_PER_VIEW),
      spaceBetween: SPACE_BETWEEN,
    },
    list = [],
    onClickCard = () => {},
    update,
    remove,
  } = props;

  const api = useAPI();
  const currentStoreId = useSelector((state) => getters.getStoreId(state));
  const additionItems = useSelector((state) => getters.getAdditionItems(state));

  const [qty, setQty] = useState(0);

  useEffect(() => {}, []);

  return (
    <>
      <SwiperBlockTitleBar>
        <SwiperBlockTitle className="title">{title}</SwiperBlockTitle>
        <SwiperBlockCheckMore>
          <Link to={'/addition'}>看更多</Link>
        </SwiperBlockCheckMore>
      </SwiperBlockTitleBar>

      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView={swiper.slidesPerView}
        slidesPerGroup={swiper.slidesPerGroup}
        spaceBetween={swiper.spaceBetween}
      >
        {list.map((item, idx) => {
          return (
            <SwiperSlide key={idx}>
              <AdditionItem
                inCart={true}
                data={item}
                update={update}
                remove={remove}
                goNext={(id) => {}}
                key={`${idx}-${item.id}`}
              />
            </SwiperSlide>
          );
        })}

        <SwiperSlide>
          <Card
            onClick={() => {
              checkMore();
            }}
          >
            <img src={ImgArrowRight} />
            <div style={{ marginTop: 15 }}>看全部</div>
          </Card>
        </SwiperSlide>

        {/* {list.map((item, idx) => {
          return (
            <SwiperSlide key={idx}>
              <ProductItem>
                <ProductItemImg>
                  {item.watermark &&
                    item.watermark.coverVisible &&
                    item.watermark.coverImageUrl && (
                      <div className="watermark watermark-covert">
                        <img
                          alt={item.brand ? item.brand : item.productName}
                          src={item.watermark.coverImageUrl}
                        />
                      </div>
                    )}
                  {item.watermark &&
                    item.watermark.brandVisible &&
                    item.watermark.brandImageUrl && (
                      <div className="watermark watermark-lt watermark watermark-lt watermark-homepage watermark-menu">
                        <img
                          alt={item.brand ? item.brand : item.productName}
                          src={item.watermark.brandImageUrl}
                        />
                      </div>
                    )}
                  {item.watermark &&
                    item.watermark.discountVisible &&
                    item.watermark.discountImageUrl && (
                      <div className="watermark watermark-rt watermark-homepage watermark-menu">
                        <img
                          alt={item.watermark.discountValue}
                          src={item.watermark.discountImageUrl}
                        />
                      </div>
                    )}
                  {item.watermark &&
                    item.watermark.calloutMsgVisible &&
                    item.watermark.calloutMsgImageUrl && (
                      <div
                        className={`watermark watermark-fullwidth watermark-lb ${
                          item.watermark.markVisible
                            ? 'watermark-menu-callout'
                            : 'watermark-menu-callout-full'
                        }`}
                      >
                        <img
                          alt={item.watermark.productName}
                          src={item.watermark.calloutMsgImageUrl}
                        />
                      </div>
                    )}
                  {item.watermark &&
                    item.watermark.markVisible &&
                    item.watermark.markImageUrl && (
                      <div className="watermark watermark-rb watermark-homepage watermark-menu">
                        <img
                          alt={item.brand ? item.brand : item.productName}
                          src={item.watermark.markImageUrl}
                        />
                      </div>
                    )}

                  <img src={item.image_path} alt="" onClick={() => {}} />

                  <CounterWrapper>
                    <Amounter
                      // max={item.max}
                      onCollapse={(quantity) => {
                        // updateCart(item.id, quantity);
                        // setQty(quantity - 1);
                        // setUpdatePage(true);
                      }}
                      onAdd={(quantity) => {
                        updateCart(item.id, quantity, true);
                        // let dataGA = {
                        //   ...item,
                        //   currency: 'TWD',
                        //   salePrice: item.price,
                        //   price: item.basePrice,
                        //   name: item.itemName,
                        //   c_Specification: item.itemSpec,
                        //   c_packageQty: item.qty,
                        // };
                        // setAddToCart(hashAccount, dataGA, productId, 1, pageName);
                      }}
                      onReduce={(quantity) => {
                        updateCart(item.id, quantity, false);
                      }}
                      // onChangeQuantity={(quantity) => {
                      //   updateCart(item.id, quantity);
                      // }}
                      defaultAmount={qty}
                    />
                  </CounterWrapper>
                </ProductItemImg>

                <ProductItemPriceBlock>
                  <ProductItemPrice>
                    <span>$</span>
                    {item.promo_price}
                  </ProductItemPrice>

                  {item.promo_price !== item.price ? (
                    <ProductItemOriginalPrice>
                      ${item.price}
                    </ProductItemOriginalPrice>
                  ) : undefined}
                </ProductItemPriceBlock>

                {item.productPromotions &&
                  item.productPromotions.length > 0 &&
                  item.productPromotions.map((promotion) => (
                    <div>
                      {promotion.calloutMsg != '' && (
                        <div className="showDetail-block">
                          <div className="showtitle">
                            <span className="showcontent">
                              {promotion.calloutMsg}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <ProductItemTitle>{item.title}</ProductItemTitle>
              </ProductItem> 
            </SwiperSlide>
          );
        })} */}
      </Swiper>
    </>
  );
};

export default SwiperProduct;
