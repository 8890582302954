import React, { useEffect, useState } from 'react';
import store, { actions } from 'redux/store';
import { navigate } from 'gatsby';
import { useAPI } from 'utils/api';
import styled from 'styled-components';
import SwiperRecommendProduct from 'components/Swiper/SwiperRecommendProduct';
import { logGetRecommendData } from 'utils/_omsLog';

const SwiperProductWrapper = styled.div`
  margin-top: 16px;
`;

const RecommendBlock = ({ remove, update }) => {
  const api = useAPI();
  const [recommendList, setRecommendList] = useState([]);

  const fetchRecommendItemList = () => {
    setRecommendList([]);
    api
      .getRecommendItems()
      .then((res) => {
        logGetRecommendData(res);
        store.dispatch(actions.setRecommendData(res));
        if (res.rows.length > 0) {
          let arrRecommends = [];
          for (let i = 0; i < res.shows; i++) {
            if (i >= res.rows.length - 1) {
              break;
            }
            arrRecommends.push(res.rows[i]);
          }
          setRecommendList(arrRecommends);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchRecommendItemList();
  }, []);

  const reload = () => {
    update();
  };

  return (
    <div>
      {recommendList.length > 0 && (
        <SwiperProductWrapper>
          <SwiperRecommendProduct
            title="推薦商品"
            list={recommendList}
            update={reload}
            remove={remove}
            checkMore={() => {
              navigate('/recommend');
            }}
            onClickCard={() => {}}
          />
        </SwiperProductWrapper>
      )}
    </div>
  );
};
export default RecommendBlock;
